import { graphql } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";
import styled from "styled-components";

import GhostHead from "components/ghost/GhostHead";
import { Layout, TagTopicGrid } from "../components";
import { chunkAllPosts } from "../../../utils/chunkAllPosts";

const TagCover = styled.section`
    --cover-height: 50vh;
    --gap: 3.6rem;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    margin-bottom: -16px;
    min-height: var(--cover-height, 50vh);
    padding-bottom: 6.4rem;
    padding-top: 6.4rem;
    position: relative;
    padding-left: var(--gap);
    padding-right: var(--gap);
    img {
        height: 100%;
        inset: 0;
        -o-object-fit: cover;
        object-fit: cover;
        position: absolute;
        width: 100%;
        z-index: -1;
        --color-base: #3c484e;
    }
`;
const TagCoverContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    max-width: 720px;
    text-align: center;
    width: 100%;
`;
const TagCoverTitle = styled.h1`
    font-family: var(--font-sans);
    letter-spacing: -0.01em;
    font-size: 7.2rem;
    text-transform: uppercase;
    color: #fff;
    line-height: 1.15;
    font-weight: bold;
    margin: 0;
`;
const TagCoverDescription = styled.p`
    font-family: var(--font-serif);
    color: #fff;
    font-size: 1.9rem;
    margin-top: 20px;
`;

const TagHeader = styled.section`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    max-width: 560px;

    h1 {
        font-size: 2.4rem;
        letter-spacing: -0.01em;
        text-transform: uppercase;
    }
`;

/* eslint-disable react/prop-types */
export const Head = ({ data, location }) => {
    const metaProps = { data, location, type: "series" };
    return <GhostHead metaProps={metaProps} />;
};
/* eslint-enable react/prop-types */

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 * @param root0
 * @param root0.data
 * @param root0.cardsLimit
 */
const Tag = ({ data, cardsLimit = 7 }) => {
    const tag = data.ghostTag;
    const posts = data.allGhostPost.edges;
    const grids = chunkAllPosts(posts, cardsLimit);

    return (
        <Layout isHeadTransparent={tag.feature_image !== null}>
            {tag.feature_image && (
                <TagCover>
                    <img sizes="100vw" src={tag.feature_image} alt={tag.name} />
                    <TagCoverContent>
                        <TagCoverTitle>{tag.name}</TagCoverTitle>
                        {tag.description && (
                            <TagCoverDescription>
                                {tag.description}
                            </TagCoverDescription>
                        )}
                    </TagCoverContent>
                </TagCover>
            )}
            <main className="site-main gh-main">
                <div className="container gh-inner">
                    {!tag.feature_image && (
                        <TagHeader>
                            <h1>{tag.name}</h1>
                            {tag.description ? <p>{tag.description}</p> : null}
                        </TagHeader>
                    )}
                    {grids.map((gird) => (
                        <article
                            key={gird}
                            data-sal="fade"
                            data-sal-delay="500"
                        >
                            <TagTopicGrid data={gird} />
                        </article>
                    ))}
                </div>
            </main>
        </Layout>
    );
};

Tag.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.object.isRequired,
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    cardsLimit: PropTypes.number,
};

export default Tag;

export const pageQuery = graphql`
    query GhostTagQuery($slug: String!) {
        ghostTag(slug: { eq: $slug }) {
            ...GhostTagFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            filter: {
                tags: { elemMatch: { slug: { eq: $slug } } }
                authors: { elemMatch: { slug: { ne: "gliacloud" } } }
            }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
