export function chunkAllPosts(chunkingPosts, perChunk) {
    return chunkingPosts.reduce((result, post, index) => {
        const chunkIndex = Math.floor(index / perChunk);
        if (!result[chunkIndex]) {
            result.push([]);
        }
        result[chunkIndex].push(post);
        return result;
    }, []);
}
